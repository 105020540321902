import { InfiniteScrollEvent, ScrollStats, PositionStats } from './models';
import {
  Directive, ElementRef, Input, Output,
  EventEmitter, OnDestroy, OnInit,
  SimpleChanges, NgZone
} from '@angular/core';
import { PositionResolverFactory } from './position-resolver';
import { ScrollRegister, ScrollRegisterConfig } from './scroll-register';
import { ScrollResolver } from './scroll-resolver';
import { Subscription } from 'rxjs/Rx';



export class InfiniteScroll implements OnDestroy, OnInit {
   scrolled = new EventEmitter<InfiniteScrollEvent>();
   scrolledUp = new EventEmitter<InfiniteScrollEvent>();

   _distanceDown: number = 2;
   _distanceUp: number = 1.5;
   _throttle: number = 300;
   _disabled: boolean = false;
   _container: any = null;
   scrollWindow: boolean = true;
   _immediate: boolean = false;
   _horizontal: boolean = false;
   _alwaysCallback: boolean = false;
  
  set debounce(value: string | boolean) {
    this.throttleType = value === '' || !!value ? 'debounce' : 'throttle';
  }

  private throttleType: string = 'throttle';
  private disposeScroller: Subscription;

  constructor(
    private element: ElementRef,
    private zone: NgZone,
    private positionResolverFactory: PositionResolverFactory,
    private scrollRegister: ScrollRegister,
    private scrollerResolver: ScrollResolver
  ) {}

  ngOnInit() {
    if (typeof window !== 'undefined') {
      const containerElement = this.resolveContainerElement();
      const positionResolver = this.positionResolverFactory.create({
        windowElement: containerElement,
        horizontal: this._horizontal
      });
      const options: ScrollRegisterConfig = {
        container: positionResolver.container,
        throttleType: this.throttleType,
        throttleDuration: this._throttle,
        filterBefore: () => !this._disabled,
        mergeMap: () => positionResolver.calculatePoints(this.element),
        scrollHandler: (container: PositionStats) => this.handleOnScroll(container)
      };
      this.disposeScroller = this.scrollRegister.attachEvent(options);
    }
  }

  handleOnScroll(container: PositionStats) {
    const scrollResolverConfig = {
      distance: {
        down: this._distanceDown,
        up: this._distanceUp
      }
    };
    const scrollStats: ScrollStats = this.scrollerResolver.getScrollStats(container, scrollResolverConfig);
    if (this.shouldTriggerEvents(scrollStats.shouldScroll)) {
      const infiniteScrollEvent: InfiniteScrollEvent = {
        currentScrollPosition: container.scrolledUntilNow
      };
      if (scrollStats.isScrollingDown) {
        this.onScrollDown(infiniteScrollEvent);
      } else {
        this.onScrollUp(infiniteScrollEvent);
      }
    }
  }

  shouldTriggerEvents(shouldScroll: boolean) {
    return (this._alwaysCallback || shouldScroll) && !this._disabled;
  }

  ngOnDestroy () {
    if (this.disposeScroller) {
      this.disposeScroller.unsubscribe();
    }
  }

  onScrollDown(data: InfiniteScrollEvent = { currentScrollPosition: 0 }) {
    this.zone.run(() => this.scrolled.emit(data));
  }

  onScrollUp(data: InfiniteScrollEvent = { currentScrollPosition: 0 }) {
    this.zone.run(() => this.scrolledUp.emit(data));
  }

  private resolveContainerElement(): any {
    if (this._container) {
      return typeof(this._container) === 'string' ?  window.document.querySelector(this._container) : this._container;
    } else {
      return this.scrollWindow ? window : this.element;
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[infinite-scroll]'
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: NgZone, },
{type: PositionResolverFactory, },
{type: ScrollRegister, },
{type: ScrollResolver, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'scrolled': [{ type: Output },],
'scrolledUp': [{ type: Output },],
'_distanceDown': [{ type: Input, args: ['infiniteScrollDistance', ] },],
'_distanceUp': [{ type: Input, args: ['infiniteScrollUpDistance', ] },],
'_throttle': [{ type: Input, args: ['infiniteScrollThrottle', ] },],
'_disabled': [{ type: Input, args: ['infiniteScrollDisabled', ] },],
'_container': [{ type: Input, args: ['infiniteScrollContainer', ] },],
'scrollWindow': [{ type: Input, args: ['scrollWindow', ] },],
'_immediate': [{ type: Input, args: ['immediateCheck', ] },],
'_horizontal': [{ type: Input, args: ['horizontal', ] },],
'_alwaysCallback': [{ type: Input, args: ['alwaysCallback', ] },],
'debounce': [{ type: Input },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
